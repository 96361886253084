import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './socialwall.less'

const mapStateToProps = ({ virtualEvent, virtualUser }) => ({ virtualEvent, virtualUser })

@connect(mapStateToProps)
class SocialWall extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }
  state = {
    numPages: null,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isSocialWallOpen: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isSocialWallOpen,
        socialWallURL,
        widgetID 
      },
      virtualUser: { id,name,email }
    } = this.props
    const { numPages } = this.state

    return (
      <div>
        <Modal
          visible={isSocialWallOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="socialwallModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                {socialWallURL && socialWallURL.endsWith('.pdf') ?

                  <div className="Example">
                    <div className="Example__container">
                      <div className="Example__container__document">
                        <Document file={socialWallURL} onLoadSuccess={this.onDocumentLoadSuccess}>
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                          ))}
                        </Document>
                      </div>
                    </div>
                  </div>
                  :
                  <Iframe
                    url={`${socialWallURL}?userid=${id}&name=${name}&email=${email}&widgetid=${widgetID}`}
                    width="100%"
                    height="calc(100vh - 50px)"

                    loading="eager"
                    className="theiFrame"
                    display="initial"
                    position="relative"
                    frameborder="0"
                    allow="camera"

                  />}
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default SocialWall
