import React from 'react'
import { Row, Col, Avatar, Button, Tooltip } from 'antd'
import { connect } from 'react-redux'
// import { push } from 'react-router-redux'
import uuid from 'react-uuid'
// import { push } from 'react-router-redux'
import moment from 'moment'
import actions from 'redux/virtual/meetings/actions'
import capitalizeFirstLetters from 'capitalize-first-letters'
import { CheckOutlined, CloseOutlined, SendOutlined, CaretRightOutlined } from '@ant-design/icons'

import './allmeetingslist.less'

const mapStateToProps = ({ meetings, router,virtualEvent }) => ({ meetings, router,virtualEvent })
@connect(mapStateToProps)
class MeetingList extends React.Component {
  acceptRequest = event => {
    const { dispatch, meetingType } = this.props
    const id = event.target.getAttribute('data-id')
    dispatch({
      type: actions.ACCEPT_REQUEST,
      payload: {
        id,
        meetingType,
        meetingID: uuid(),
      },
    })
  }

  startMeeting = (meetingData) => {
    const {
      dispatch,
      // router: {
      //   location: { pathname, query },
      // },
      virtualEvent: { currentWhereByStep, scene: { id } },
    } = this.props
    const{
      // meetingID,
      meetingURL,
      // message,
      meetingTopic
      }=meetingData
    console.log('meetingData',meetingData);
    // let querySelectors = '?'
    // const meetingID = event.target.getAttribute('data-meetingid')
    // let counter = 0
    // const totalQueries = Object.keys(query).length
    // Object.entries(query).map(key => {
    //   querySelectors += `${key[0]}=${key[1]}`
    //   if (counter < totalQueries - 1) {
    //     querySelectors += `&`
    //   }
    //   counter += 1
    //   return ''
    // })
    // dispatch(push(`${pathname}${querySelectors}&meeting=${meetingID}`))
    dispatch({
      type: 'virtualEvent/SET_STATE',
      payload: {
        isWhereByMeetOpen: true,
        whereByMeetURL: meetingURL,
        whereByMeetHostURL: meetingURL,
        tableName: meetingTopic,
        currentWhereByStep: currentWhereByStep + 1
      },
    })
    dispatch({
      type: 'virtualEvent/UPDATE_USER_WIDGET_ANALYTICS',
      payload: {
        widgetid: `${id}-videocall`
      },
    })
  }

  getMeetingItem = (item, meetingType) => {
    const avatar =
      item[1].user.avatarType === 'text'
        ? `https://via.placeholder.com/50x50.png/${item[1].user.avatarColor.substring(
            1,
          )}/fff?text=${item[1].user.avatarData}`
        : item[1].user.avatarData
    return (
      <div className={`meetingItem ${item[1].currentStatus}`}>
        <Row justify="space-around" align="middle" className="internal_item">
          <Col xs={8} md={8} lg={4} xl={4} className="text-center avatarDesign">
            <Avatar src={avatar} size="90" />
          </Col>
          <Col xs={16} md={16} lg={12} xl={12}>
            <div>
              <h2>
                <b>{capitalizeFirstLetters(item[1].user.name)}</b>
              </h2>
              <h4>{capitalizeFirstLetters(item[1].user.organization)}</h4>
              <span>{capitalizeFirstLetters(item[1].user.designation)}</span>
              <br />
              <span>
                <b>Scheduled at {item[1].startTime.split(' ')[1]}</b>
              </span>
            </div>
          </Col>
          {meetingType === 'sent' || meetingType === 'confirmed' ? (
            <>
              <Col xs={0} md={0} lg={2} xl={2} className="text-center">
                {/* <Tooltip placement="topLeft" title="Send Direct Message" arrowPointAtCenter>
                  <Button type="primary" shape="circle" icon={<SendOutlined />} size="medium" />
                </Tooltip> */}
              </Col>
              {meetingType === 'confirmed' ? (
                <Col xs={0} md={0} lg={6} xl={6} className="text-center">
                  <Tooltip placement="topLeft" title="Join Meeting" arrowPointAtCenter>
                    <Button
                      type="primary"
                      data-meetingid={item[1].meetingID}
                      onClick={()=>this.startMeeting(item[1])}
                      shape="round"
                      icon={<CaretRightOutlined />}
                      size="medium"
                    >
                      Join Meeting
                    </Button>
                  </Tooltip>
                </Col>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <Col xs={0} md={0} lg={2} xl={2} className="text-center">
                <Tooltip placement="topLeft" title="Send Direct Message" arrowPointAtCenter>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<SendOutlined />}
                    size="medium"
                    ghost
                  />
                </Tooltip>
              </Col>
              <Col xs={0} md={0} lg={2} xl={2} className="text-center">
                <Tooltip placement="topLeft" title="Reject Request" arrowPointAtCenter>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<CloseOutlined />}
                    size="medium"
                    danger
                  />
                </Tooltip>
              </Col>
              <Col xs={0} md={0} lg={2} xl={2} className="text-center">
                <Tooltip placement="topLeft" title="Accept Request" arrowPointAtCenter>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<CheckOutlined />}
                    data-id={item[1].user.id}
                    onClick={this.acceptRequest}
                    size="medium"
                  />
                </Tooltip>
              </Col>
            </>
          )}
        </Row>
      </div>
    )
  }

  render() {
    const {
      meetings: { allMeetings },
      meetingType,
    } = this.props
    let currentDate = ''
    const showcase = Object.entries(allMeetings[meetingType]).map(key => {
      const onlyDate = key[1].startTime.split(' ')[0]
      if (onlyDate !== currentDate) {
        // const tempTime = key[1].startTime.split(' ')[0]
        currentDate = onlyDate
        return (
          <>
            <h4>{moment(key[1].startTime).format('dddd, MMMM Do YYYY')}</h4>
            {this.getMeetingItem(key, meetingType)}
          </>
        )
      }
      return this.getMeetingItem(key, meetingType)
    })
    return <div className="thelist">{showcase}</div>
  }
}

export default MeetingList
